import React from 'react'

import Layout from '../components/layout'
import ProductList from '../components/giftfinder/components/ProductList'

export default function Article({pageContext}){
    return (
    <Layout>
        <ProductList products={pageContext.products}/>
        {/* <div>{pageContext.datum}</div> {console.log(pageContext)} */} 
        <div dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
    </Layout>
    )
}